import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import logo from '../images/logo svg.svg'
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScienceOpen, setIsScienceOpen] = useState(false);
  const [isNewsOpen, setIsNewsOpen] = useState(false);
  const location = useLocation(); // Get the current location

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Toggle for Science dropdown in mobile view
  const toggleScience = () => {
    setIsScienceOpen(!isScienceOpen);
  };

  // Toggle for News & Updates dropdown in mobile view
  const toggleNews = () => {
    setIsNewsOpen(!isNewsOpen);
  };

  // Function to determine if the link is active
  const isActive = (path) => location.pathname === path;

  return (
    <nav className="bg-black px-8 lg:px-24 xl:px-32">
      <div className="container mx-auto flex justify-between text-white items-center py-6">
        <div className="flex items-center space-x-4">
          <img src={logo} height={60} width={60} alt="Centella Logo" />
          <div className="text-xl font-medium flex font-jost items-center space-x-0.5">
            <span>CENTELLA AI <br></br>THERAPEUTICS</span>
            
            
          </div>
        </div>
        <div className="hidden md:flex space-x-8 text-lg items-center">
          <Link
            to="/"
            className={`hover:text-purple-500 ${isActive('/') ? 'border-b-2 border-purple-500' : ''}`}
          >
            Platform
          </Link>
          <Link
            to="/pipeline"
            className={`hover:text-purple-500 ${isActive('/pipeline') ? 'border-b-2 border-purple-500' : ''}`}
          >
            Pipeline
          </Link>
          <div className="relative group">
            <button className="hover:text-purple-500 focus:outline-none">
              Science
            </button>
            <div className="absolute right-0 mt-2 w-48 bg-black text-white border border-gray-200 shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 group-hover:block rounded-md">
              <Link
                to="/publications"
                className="block px-4 py-2 hover:bg-gray-700"
              >
                Publications
              </Link>
              <Link
                to="/case-study"
                className="block px-4 py-2 hover:bg-gray-700"
              >
                Case Study
              </Link>
            </div>
          </div>

          <div className="relative group">
            <button className="hover:text-purple-500 focus:outline-none">
              News & Updates  
            </button>
            <div className="absolute right-0 border border-white mt-2 w-48 bg-black text-white shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 group-hover:block rounded-md">
              <Link
                to="/news-list"
                className="block px-4 py-2 hover:bg-gray-700"
              >
                Media Coverage
              </Link>
              <Link
                to="/blogs"
                className="block px-4 py-2 hover:bg-gray-700"
              >
                Blogs
              </Link>
              <Link
                to="/press-release"
                className="block px-4 py-2 hover:bg-gray-700"
              >
                Press Release
              </Link>

              <Link
                to="/careers"
                className="block px-4 py-2 hover:bg-gray-700"
              >
                careers
              </Link>
            </div>
          </div>

          <Link
            to="/teams"
            className={`hover:text-purple-500 ${isActive('/teams') ? 'border-b-2 border-purple-500' : ''}`}
          >
            About Us
          </Link>
          <Link
            to="/contact"
            className="text-white font-semibold py-2 px-4 rounded-[50px] border-2 border-[#6FC5F0] hover:border-blue-500 transition duration-300"
          >
            Contact Us
          </Link>
        </div>
        <div className="md:hidden">
          <button onClick={toggleMenu}>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
              ></path>
            </svg>
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden">
          <div className="flex flex-col space-y-4 mt-4 px-8">
            <Link
              to="/"
              className={`hover:text-purple-500 text-white ${isActive('/') ? 'border-b-2 border-purple-500' : ''}`}
            >
              Platform
            </Link>
            <Link
              to="/pipeline"
              className={`hover:text-purple-500 text-white ${isActive('/pipeline') ? 'border-b-2 border-purple-500' : ''}`}
            >
              Pipeline
            </Link>
            <div className="relative">
              <button onClick={toggleScience} className="hover:text-purple-500 focus:outline-none text-white">
                Science
              </button>
              {isScienceOpen && (
                <div className="mt-2 pl-4">
                  <Link
                    to="/publications"
                    className="block py-2 hover:text-purple-500 text-white"
                  >
                    Publications
                  </Link>
                  <Link
                    to="/case-study"
                    className="block py-2 hover:text-purple-500 text-white"
                  >
                    Case Study
                  </Link>
                </div>
              )}
            </div>
            <div className="relative">
              <button onClick={toggleNews} className="hover:text-purple-500 focus:outline-none text-white">
                News & Updates
              </button>
              {isNewsOpen && (
                <div className="mt-2 pl-4">
                  <Link
                    to="/news-list"
                    className="block py-2 hover:text-purple-500 text-white"
                  >
                    Media Coverage
                  </Link>
                  <Link
                    to="/blogs"
                    className="block py-2 hover:text-purple-500 text-white"
                  >
                    Blogs
                  </Link>
                  <Link
                    to="/press-release"
                    className="block py-2 hover:text-purple-500 text-white"
                  >
                    Press Release
                  </Link>
                </div>
              )}
            </div>
            <Link
              to="/teams"
              className={`hover:text-purple-500 text-white ${isActive('/teams') ? 'border-b-2 border-purple-500' : ''}`}
            >
              About Us
            </Link>
            <Link
              to="/contact"
              className={`button hover:text-purple-500 text-white ${isActive('/contact') ? 'border-b-2 border-purple-500' : ''}`}
            >
              Contact Us
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
