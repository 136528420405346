import React from 'react';

const Careers = () => {
  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col items-center py-12">
      {/* Replace "Our Company" with your actual company name */}
      <h1 className="text-4xl font-bold text-[#6FC5F0] mb-8">Careers at Centella AI Therapeutics</h1>
      
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg max-w-4xl w-full">
        <h2 className="text-3xl font-semibold text-[#6FC5F0] mb-4">Backend Developer (Spring Boot)</h2>
        <p className="text-lg mb-6">
          Join our team as a Backend Developer! You’ll work with cutting-edge technologies like Spring Boot to build scalable and efficient backend services.
        </p>

        <div className="text-lg space-y-4 mb-6">
          <p><strong>Positions Available:</strong> 2</p>
          <p><strong>Required Skills:</strong> Spring Boot, Java, REST APIs, Microservices</p>
        </div>

        <div className="text-center">
          {/* This button will open the user's email client with the pre-filled email */}
          <a 
            href="mailto:hr@centella.ai"
            className="bg-yellow-500 hover:bg-yellow-400 text-black font-semibold py-2 px-6 rounded-full transition duration-300"
          >
            Apply Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default Careers;
